<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack()">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10032')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container">
		<div id="sub" class="sub notice">
			<!-- content -->
			<section class="content" v-if="mcs0101.length > 0">
				<div class="box">
					<!-- 공지사항이 있을 경우 -->
					<!-- toggle list -->
					<ul class="accordion">
						<li v-for="(item, index) in mcs0101" :key="index">
							<a class="accordion__question" :class="{'active' : showNoticeList.includes(index)}" @click="onClickShowNotice(index)">
								<div class="accordion__info" style="max-width: calc(100% - 26px);">
									<span class="accordion__title ellipsis">
										{{item.TITLE}}
									</span>
									<span class="accordion__date">
										{{dayjs(item.YMD).format('YYYY년 MM월 DD일')}}
									</span>
								</div>
								<div class="accordion__dropdown">
									<i class="icon icon-down"></i>
								</div>
							</a>
							<div class="accordion__answer" style="white-space: pre-wrap;" v-show="showNoticeList.includes(index)" v-html="item.NOTICE_DESC">
							</div>
						</li>
					</ul>
				</div>
				<div class="pager">
					<a @click="onClickStartPage">
						<i class="icon icon-first"></i>
					</a>
					<a @click="onClickPrevPage">
						<i class="icon icon-left--light"></i>
					</a>
					<a 
						v-for="index in Math.min(maxPage, 5)" 
						:key="index" 
						:class="{'active' : (page === pageOffset + index)}"
						@click="onClickMovePage(pageOffset + index)"
					>
						{{pageOffset + index}}
					</a>
					<a @click="onClickNextPage">
						<i class="icon icon-right--light"></i>
					</a>
					<a @click="onClickEndPage">
						<i class="icon icon-last"></i>
					</a>
				</div>
			</section>
			<section class="content" v-else>
				<div class="centered">
					<div class="centered__wrap">
						<div class="textbox">
							<div class="textbox__icon">
								<img src="@/assets/images/img_speaker.png" alt="등록된 공지사항이 없어요" style="width:64px">
							</div>
							<h3 class="textbox__title">{{t('10033')}}</h3>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	setup() {
		const router = useRouter();
		const store = useStore();
		const mcs0101 = computed(() => store.state.mcs01.mcs0101);

		const showNoticeList = ref([]);

		const totalItem = computed(()=> mcs0101.value.length > 0	? mcs0101.value[0].TOTAL_RECORDS : 0);
		const maxPage = computed(() => Math.ceil(totalItem.value / 10));
    const page = ref(1);
    const startPage = computed(() => (page.value - 1)/5 * 5 + 1);
    const endPage = computed(() => Math.min(startPage.value + 4, maxPage.value));
    const pageOffset = computed(() => Math.max(Math.min(page.value - 3, maxPage.value - 5), 0));
	const { t }= useI18n() //번역필수 모듈

		onMounted(()=>{
			store.dispatch("mcs01/fetchMcs0101", {
				proc_cd: "01",
				pagesize: 10,
				gotopage: 1
			});
		});

		const updatePage = (index) => {
			if(page.value !== index){
				store.dispatch("mcs01/fetchMcs0101", {
					proc_cd: "01",
					pagesize: 10,
					gotopage: index
				});
				page.value = index;
				window.scrollTo(0, 0);
			}
		}

		const onClickMovePage = (index) => {
			showNoticeList.value = [];
			updatePage(index);
		}

		const onClickNextPage = () => {
			if(page.value < maxPage.value){
				updatePage(page.value + 1);
			}
		}
		const onClickPrevPage = () => {
			if(page.value > 1){
				updatePage(page.value - 1);
			}
		}	

		const onClickEndPage = () => {
			updatePage(maxPage.value);
		}
		const onClickStartPage = () => {
			updatePage(1);
		}

		const onClickShowNotice = (index) => {
			if(showNoticeList.value.includes(index)) {
				showNoticeList.value = showNoticeList.value.filter(item => item !== index);
			}
			else {
				showNoticeList.value.push(index);
			}
		}

		const goBack = () => {
			router.back();
		}

		return {
			goBack,
			mcs0101,
			dayjs,
			showNoticeList,
			onClickShowNotice,
      maxPage,
      page,
      onClickMovePage,
      onClickNextPage,
      onClickPrevPage,
      onClickEndPage,
      onClickStartPage,
      startPage,
	  i18n,
      endPage,
      pageOffset,
	  t
		}
	},
}
</script>